import React from 'react';

class Clock extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {timer: null, date: new Date()};
	}
	componentDidMount() {
		var timer = this.state.timer;
		timer = setInterval(() => this.tick(),1000);
		this.setState({timer});
	}
	componentWillUnmount() {
		var timer = this.state.timer;
		clearInterval(timer);
		this.setState({timer});
	}
	tick() {
		this.setState({date: new Date()});
	}
	render() {
		return(
			<div className={this.props.className !== undefined ? this.props.className : {}}>
				{this.state.date.toLocaleTimeString()}
			</div>
		);
	}
}
export default Clock;
 