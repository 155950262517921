import React from 'react';
import './index.scss';
import {BsEye,BsEyeSlash,BsDownload,BsSearch} from "react-icons/bs";
let fileReader;

class Input extends React.Component {
	constructor(props) {
		super(props);
		this.fileInput = React.createRef();
		this.state = {
			type: this.props.type !== undefined ? this.props.type : '',
			error: this.props.error !== undefined ? this.props.error : '',
		};
	}
	validValue = (value) => {
		var error = this.state.error;

		switch(this.props.type){
			case 'text':
				if(this.props.max !== undefined && value.length > parseInt(this.props.max)){
					error = 'Valeur trop longue !';
				}
				else if(this.props.min !== undefined && value.length < parseInt(this.props.min)){
					error = 'Valeur trop courte !';
				}
				else{
					error = '';
				}
			break;
			case 'pourcent':
				if(value < 0 && value > 100){
					error = 'Valeur trop longue !';
				}
				else{
					value = value / 100;
					error = '';
				}
			break;
			case 'number':
				if(isNaN(parseFloat(value)) === false){
					if(this.props.precision !== undefined && isNaN(parseInt(this.props.precision)) === false){
						value = value.toFixed(this.props.precision);
						if(this.props.max !== undefined && isNaN(parseFloat(this.props.max)) === false && value > parseFloat(this.props.max).toFixed(this.props.precision)){
							error = 'Valeur trop grande !';
						}
						else if(this.props.min !== undefined && isNaN(parseFloat(this.props.min)) === false && value < parseFloat(this.props.min).toFixed(this.props.precision)){
							error = 'Valeur trop petite !';
						}
						else{
							error = '';
						}
					}
					else{
						if(this.props.max !== undefined && isNaN(parseInt(this.props.max)) === false && value > parseInt(this.props.max)){
							error = 'Valeur trop grande !';
						}
						else if(this.props.min !== undefined && isNaN(parseInt(this.props.min)) === false && value < parseInt(this.props.min)){
							error = 'Valeur trop petite !';
						}
						else{
							error = '';
						}
					}
				}
				else{
					error = 'Valeur erronée !';
				}
			break;
			case 'phone':
				//NEED TO CORRECT
				//Search swiss number regex
				if(value !== ''){
					error = 'Numéro invalide !';
				}
				else{
					error = '';
				}
			break;
			case 'email':
				if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) === false){
					error = 'Email invalide !';
				}
				else{
					error = '';
				}
			break;
			case 'password':
				var number = ['0','1','2','3','4','5','6','7','8','9'];
				var alphabet = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
				var special_char = ['$','^','#','@','+','-','/','\\','-','.',';',':'];
				var password_chars = value.split('');

				if(this.props.level !== undefined){

					switch(parseInt(this.props.level)){
						case 1:
							if(value.length < 4){
								error = 'Mot de passe trop cours !';
							}
							else{
								error = '';
							}
						break;
						case 2:
							if(value.length < 6){
								error = 'Mot de passe trop cours !';
							}
							else{

								if(password_chars.find(char => alphabet.indexOf(char.toUpperCase()) !== -1) !== undefined && password_chars.find(char => number.indexOf(char) !== -1) !== undefined){
									error = '';
								}
								else{
									error = 'Pas assez complexe !';
								}
							}
						break;
						case 3:
							if(value.length < 8){
								error = 'Mot de passe trop cours !';
							}
							else{
								if(password_chars.find(char => alphabet.indexOf(char.toUpperCase()) !== -1) !== undefined && password_chars.find(char => number.indexOf(char) !== -1) !== undefined && password_chars.find(char => special_char.indexOf(char) !== -1) !== undefined){
									error = '';
								}
								else{
									error = 'Pas assez complexe !';
								}
							}
						break;
						default:
							error = '';
						break;
					}
				}
				else{
					error = '';
				}
			break;
			default:
				if(this.props.max !== undefined && value.length > parseInt(this.props.max)){
					error = 'Valeur trop longue !';
				}
				else if(this.props.min !== undefined && value.length < parseInt(this.props.min)){
					error = 'Valeur trop courte !';
				}
				else{
					error = '';
				}
			break;
		}

		if(this.props.id !== undefined && this.props.onChange !== undefined){
			this.props.onChange(this.props.id, value, error !== '' ? error : null);
		}
		else if(this.props.onChange !== undefined){
			console.error('id is required in this Input');
			console.error(this.props);
		}
		this.setState({value,error});
	}
	onChange = (event) =>{
		var value = event.currentTarget.value;
		var error = this.state.error;
		if(this.props.required === true){
			if(value.length	=== 0){
				error = 'Champs obligatoire !';
				if(this.props.id !== undefined && this.props.onChange !== undefined){
					this.props.onChange(this.props.id, value, error);
				}
				this.setState({error});
			}
			else{
				this.validValue(value);
			}
		}
		else{
			this.validValue(value);
		}
	}
	//PASSWORD INPUT
	onToggleShowPassword = () =>{
		const type = this.state.type === 'password' ? 'text' : 'password';
		this.setState({type});
	}
	onGeneratePassword = () =>{

	}
	//--------------------------
	//FILE INPUT
	onSelectFile = (event) => {
		const value = event.target.value.split('\\')[2];
		const file = event.target.files[0];
		fileReader = new FileReader();
		fileReader.onloadend = this.handleFileRead;
		fileReader.readAsText(file);
		this.setState({value});
	}
	onOpenSelectFileDialog = () => {
		this.fileInput.current.click();
	}
	handleFileRead = (event) => {
		const content = fileReader.result;
		if(this.props.id !== undefined && this.props.onChange !== undefined){
			this.props.onChange(this.props.id, content, this.state.error !== '' ? true : false);
		}
	}
	//--------------------------
	render() {
		var to_render = (<div></div>);

		switch(this.props.type){
			case 'search':
				var inputBoxClassName = 'inputBox inputBox--search';

				if(this.props.label !== undefined && this.props.label !== ''){
					inputBoxClassName += ' inputBox--label'
					if(this.state.error !== ''){
						inputBoxClassName += ' inputBox--label--error';
					}
				}
				else if(this.state.error !== ''){
					inputBoxClassName += ' inputBox--error';
				}
				to_render = (
					<div className={inputBoxClassName}>
						<div className={this.props.label !== undefined ? 'inputBox__label' : 'hidden'}>
							{this.props.label !== undefined ? this.props.label : ''}
						</div>
						<div className='inputBox--search__inputDiv'>
							<input
								className={this.state.error === '' ? 'inputBox__input inputBox--search__inputDiv__input' : 'inputBox__input inputBox--search__inputDiv__input inputBox__input--error'}
								style={this.props.style !== undefined ? this.props.style : {}}
								placeholder={this.props.placeHolder !== undefined ? this.props.placeHolder : ''}
								type='text'
								value={this.props.value}
								onChange={this.onChange}
								onKeyDown={this.props.onKeyDown !== undefined ? this.props.onKeyDown : function(){}}
								disabled={this.props.disabled === true ? true : false}
							/>
							<div
								className={this.props.disabled === true ? 'inputBox--search__inputDiv__actionsBox inputBox--search__inputDiv__actionsBox--disabled' : 'inputBox--search__inputDiv__actionsBox'}
								title='Rechercher'
								onClick={this.props.onActionClick !== undefined ? this.props.onActionClick : function(){}}
							>
								<BsSearch />
							</div>
						</div>
						<div className={this.props.error !== undefined && this.props.error !== '' ? 'inputBox__helper' : 'hidden'}>
							{this.props.error !== undefined && this.state.error !== '' ? this.state.error : ''}
						</div>
					</div>
				);
			break;
			case 'pourcent':
				var inputBoxClassName = 'inputBox inputBox--pourcent';

				if(this.props.label !== undefined && this.props.label !== ''){
					inputBoxClassName += ' inputBox--label'
					if(this.state.error !== ''){
						inputBoxClassName += ' inputBox--label--error';
					}
				}
				else if(this.state.error !== ''){
					inputBoxClassName += ' inputBox--error';
				}
				to_render = (
					<div className={inputBoxClassName}>
						<div className={this.props.label !== undefined ? 'inputBox__label' : 'hidden'}>
							{this.props.label !== undefined ? this.props.label : ''}
						</div>
						<div className='inputBox--pourcent__inputDiv'>
							<input
								className={this.state.error === '' ? 'inputBox__input inputBox--pourcent__inputDiv__input' : 'inputBox__input inputBox--pourcent__inputDiv__input inputBox__input--error'}
								style={this.props.style !== undefined ? this.props.style : {}}
								placeholder={this.props.placeHolder !== undefined ? this.props.placeHolder : ''}
								type='number'
								min='0'
								max='100'
								step='1'
								value={this.props.value*100}
								onChange={this.onChange}
								onKeyDown={this.props.onKeyDown !== undefined ? this.props.onKeyDown : function(){}}
								disabled={this.props.disabled === true ? true : false}
							/>
							<div
								className={this.props.disabled === true ? 'inputBox--pourcent__inputDiv__actionsBox inputBox--pourcent__inputDiv__actionsBox--disabled' : 'inputBox--pourcent__inputDiv__actionsBox'}
								onClick={this.props.onActionClick !== undefined ? this.props.onActionClick : function(){}}
							>
								%
							</div>
						</div>
						<div className={this.props.error !== undefined && this.props.error !== '' ? 'inputBox__helper' : 'hidden'}>
							{this.props.error !== undefined && this.state.error !== '' ? this.state.error : ''}
						</div>
					</div>
				);
			break;
			case 'password':
				var inputBoxClassName = 'inputBox inputBox--password';

				if(this.props.label !== undefined && this.props.label !== ''){
					inputBoxClassName += ' inputBox--label'
					if(this.state.error !== ''){
						inputBoxClassName += ' inputBox--label--error';
					}
				}
				else if(this.state.error !== ''){
					inputBoxClassName += ' inputBox--error';
				}
				to_render = (
					<div className={inputBoxClassName}>
						<div className={this.props.label !== undefined ? 'inputBox__label' : 'hidden'}>
							{this.props.label !== undefined ? this.props.label : ''}
						</div>
						<div className='inputBox--password__inputDiv'>
							<input
								className={this.state.error === '' ? 'inputBox__input inputBox--password__inputDiv__input' : 'inputBox__input inputBox--password__inputDiv__input inputBox__input--error'}
								style={this.props.style !== undefined ? this.props.style : {}}
								placeholder={this.props.placeHolder !== undefined ? this.props.placeHolder : ''}
								type={this.state.type}
								value={this.props.value}
								onChange={this.onChange}
								onKeyDown={this.props.onKeyDown !== undefined ? this.props.onKeyDown : function(){}}
								disabled={this.props.disabled === true ? true : false}
							/>
							<div
								className={this.props.disabled === true ? 'inputBox--password__inputDiv__actionsBox inputBox--password__inputDiv__actionsBox--disabled' : 'inputBox--password__inputDiv__actionsBox'}
								title={'Générer'}
								onClick={this.props.disabled === true ? function(){} : this.onGeneratePassword}
							>
								<BsEye />
							</div>
							<div
								className={this.props.disabled === true ? 'inputBox--password__inputDiv__actionsBox inputBox--password__inputDiv__actionsBox--disabled' : 'inputBox--password__inputDiv__actionsBox'}
								title={this.state.type === 'password' ? 'Afficher' : 'Cacher'}
								onClick={this.props.disabled === true ? function(){} : this.onToggleShowPassword}
							>
								{this.state.type === 'password' ? (<BsEye />) : (<BsEyeSlash />)}
							</div>
						</div>
						<div  className={this.state.error !== undefined && this.state.error !== '' ? 'inputBox__helper' : 'hidden'}>
							{this.state.error !== undefined && this.state.error !== '' ? this.state.error : ''}
						</div>
					</div>
				);
			break;
			case 'file':
				var inputBoxClassName = 'inputBox inputBox--file';

				if(this.props.label !== undefined && this.props.label !== ''){
					inputBoxClassName += ' inputBox--label'
					if(this.state.error !== ''){
						inputBoxClassName += ' inputBox--label--error';
					}
				}
				else if(this.state.error !== ''){
					inputBoxClassName += ' inputBox--error';
				}
				to_render = (
					<div className={inputBoxClassName}>
						<div className={this.props.label !== undefined ? 'inputBox__label' : 'hidden'}>
							{this.props.label !== undefined ? this.props.label : ''}
						</div>
						<div className='inputBox--file__inputDiv'>
							<input
								className={this.state.error === '' ? 'inputBox__input inputBox--file__inputDiv__input' : 'inputBox__input inputBox--file__inputDiv__input inputBox__input--error'}
								style={this.props.style !== undefined ? this.props.style : {}}
								placeholder={this.props.placeHolder !== undefined ? this.props.placeHolder : ''}
								value={this.props.value}
								onChange={function(){}}
								disabled={this.props.disabled === true ? true : false}
							/>
							<div
								className={this.props.disabled === true ? 'inputBox--file__inputDiv__actionsBox inputBox--file__inputDiv__actionsBox--disabled' : 'inputBox--file__inputDiv__actionsBox'}
								title=''
								onClick={this.onOpenSelectFileDialog}
							>
								<BsDownload />
							</div>
							<input
							  type="file"
							  style={{ display: "none" }}
							  ref={this.fileInput}
							  accept=".csv"
							  onChange={this.onSelectFile}
							/>
						</div>
						<div className={this.props.error !== undefined && this.props.error !== '' ? 'inputBox__helper' : 'hidden'}>
							{this.props.error !== undefined && this.state.error !== '' ? this.state.error : ''}
						</div>
					</div>
				);
			break;
			default:
				var inputBoxClassName = 'inputBox';
				var inputClassName = 'inputBox__input';

				if(this.props.label !== undefined && this.props.label !== ''){
					inputBoxClassName += ' inputBox--label'
					if(this.state.error !== ''){
						inputBoxClassName += ' inputBox--label--error';
						inputClassName += ' inputBox__input--error';
					}
				}
				else if(this.state.error !== ''){
					inputBoxClassName += ' inputBox--error';
					inputClassName += ' inputBox__input--error';
				}
				to_render = (
					<div className={inputBoxClassName}>
						<div className={this.props.label !== undefined ? 'inputBox__label' : 'hidden'}>
							{this.props.label !== undefined ? this.props.label : ''}
						</div>
						<input
							className={inputClassName}
							style={this.props.style !== undefined ? this.props.style : {}}
							placeholder={this.props.placeHolder !== undefined ? this.props.placeHolder : ''}
							value={this.props.value}
							onChange={this.onChange}
							onKeyDown={this.props.onKeyDown !== undefined ? this.props.onKeyDown : function(){}}
							disabled={this.props.disabled === true ? true : false}
						/>
						<div className={this.props.error !== undefined && this.state.error !== '' ? 'inputBox__helper' : 'hidden'}>
							{this.props.error !== undefined && this.state.error !== '' ? this.state.error : ''}
						</div>
					</div>
				);
			break;
		}
		return to_render;
	}
}
export default Input;
